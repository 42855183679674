import styles from "./DefaultLayout.module.scss";
import classNames from "classnames/bind";
import { useAppDispatch, useAppSelector } from "@/features/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

const TAB_NAVIGATION = ["Todo", "Profile"];

function DefaultLayout({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const [tab, setTab] = useState(TAB_NAVIGATION[0]);
  const handleSwitch = function (item: string) {
    setTab(item);
    if (item === "Todo") {
      navigate("/");
    } else {
      navigate("/" + item);
    }
  };

  return (
    <div>
      <div className={cx("wrapper")}>
        <h5>Todo Application</h5>
        <div className={cx("content-wrapper")}>
          <div className={cx("content")}>
            <div className={cx("btn-switch")}>
              <ul className={cx("nav-switch")}>
                {TAB_NAVIGATION.map((item) => {
                  return (
                    <li key={item} className={cx("tab_navigation")}>
                      <button onClick={() => handleSwitch(item)}>{item}</button>
                      <div
                        className={cx("separate", {
                          active: tab === item,
                        })}
                      ></div>
                    </li>
                  );
                })}
              </ul>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultLayout;
