import classNames from "classnames/bind";
import styles from "@/components/Card/index.module.scss";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/features/hooks";
import { data, statuses } from "@/data";
import DropWrapper, { cartItem } from "@/components/Card/DropWrapper";
import Col from "@/components/Card/Col";
import Item from "@/components/Card/item";
import { AddIcon, ClearIcon } from "@/components/Icons";
import style from "./ActivityTemp.module.scss";
import Window from "@/components/Card/Window";
import { StatusCartItem } from "@/data/types";
import { axiosClient } from "@/configs/AxiosInterceptor";
import { TASK_PREFIX } from "@/domain/apis";
import Loading from "@/components/Loading";
import { StatusTypes, WindowType, setTypeWindow } from "@/features/toggleSlice";
import { setToast } from "@/features/toastSlice";
const cx = classNames.bind(styles);
const cxx = classNames.bind(style);

const Activitytemp = () => {
  const [items, setItems] = useState<cartItem[]>([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { task, type } = useAppSelector((state) => state.toggle);
  const dispatch = useAppDispatch();

  const onDrop = async (item: cartItem, status: StatusCartItem) => {
    const column = status;
    setItems((prevState) => {
      const newItems = prevState
        .filter((i) => {
          return i.id !== item.id;
        })
        .concat({ ...item, column });
      return [...newItems];
    });
    if (column !== item.column) {
      setLoading(true);
      await axiosClient.put(TASK_PREFIX + "/" + item.id, {
        id: item.id,
        column: column,
      });
      setLoading(false);
    }
  };

  const moveItem = (dragIndex: number, hoverIndex: number) => {
    const item = items[dragIndex];
    setItems((prevState) => {
      const newItems = prevState.filter((item, idx) => idx !== dragIndex);
      newItems.splice(hoverIndex, 0, item);
      return [...newItems];
    });
  };

  const fetchData = async () => {
    const data = await axiosClient.get(TASK_PREFIX);
    setItems(data.data.tasks ?? []);
  };

  useEffect(() => {
    fetchData();
  }, []);
  const onOpen = () => {
    dispatch(setTypeWindow(WindowType.CREATE));
    setShow(true);
  };

  const onClose = () => setShow(false);

  const clearAllItems = async () => {
    try {
      const restItems = items.filter(
        (item) => item.column !== StatusCartItem.DONE
      );

      if (restItems.length < items.length) {
        const result = await axiosClient.delete(TASK_PREFIX);
        setItems(restItems);
        dispatch(
          setToast({
            title: "Success",
            desc: result.data.title,
            isOpen: false,
            status_code: result.data.status,
          })
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (task) {
      switch (type) {
        case StatusTypes.CREATE:
          console.log(123);

          setItems((previous) => [task, ...previous]);
          break;
        case StatusTypes.DELETE_ONE:
          setItems((previous) =>
            previous.filter((item) => item.id !== task.id)
          );
          break;
        case StatusTypes.UPDATE:
          const result = items.map((item) => {
            if (item.id === task.id) {
              return {
                ...item,
                ...task,
              };
            }
            return item;
          });
          setItems(result);
          break;
      }
    }
  }, [task, type]);

  return (
    <div className={cx("row")}>
      {statuses.map((s) => {
        return (
          <div key={s.status} className={cx("col-wrapper")}>
            <div className={cxx("header_wrapper")}>
              <h2 className={cx("col-header")}>{s.status.toUpperCase()}</h2>
              {s.status === StatusCartItem.TODO && (
                <button onClick={onOpen}>Add {AddIcon}</button>
              )}
              {s.status === StatusCartItem.DONE && (
                <button onClick={clearAllItems}>Clear all</button>
              )}
            </div>
            <DropWrapper onDrop={onDrop} status={s.status}>
              {/*@ts-ignore*/}
              <Col>
                {items
                  .filter((i) => i.column === s.status)
                  .map((i, idx) => {
                    return (
                      <Item
                        key={i.id}
                        item={i}
                        index={idx}
                        moveItem={moveItem}
                        status={s}
                        loading={loading}
                      />
                    );
                  })}
              </Col>
            </DropWrapper>
          </div>
        );
      })}
      <Window onClose={onClose} show={show} />
    </div>
  );
};

export default Activitytemp;
