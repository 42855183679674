import { statuses } from "@/data";
import ITEM_TYPE, { StatusCartItem } from "@/data/types";
import React from "react";
import { useDrop } from "react-dnd";
import styles from "./index.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export interface cartItem {
  id: string;
  icon: string;
  status: string;
  title: string;
  content: string;
  index?: number;
  column: StatusCartItem;
  description: string;
  email: string;
  createdAt: Date;
  updatedAt: Date;
}

type propTypes = {
  onDrop: (item: cartItem, status: StatusCartItem) => void;
  children: React.ReactElement;
  status: StatusCartItem;
};

const DropWrapper = ({ onDrop, children, status }: propTypes) => {
  // @ts-ignore
  const [{ isOver }, drop] = useDrop<any>({
    accept: ITEM_TYPE,
    canDrop: (item: cartItem, monitor) => {
      const itemIndex = statuses.findIndex((si) => si.status === item.column);
      const statusIndex = statuses.findIndex((si) => si.status === status);
      return [itemIndex + 1, itemIndex - 1, itemIndex].includes(statusIndex);
    },
    drop: (item, monitor) => {
      onDrop(item, status);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });
  return (
    <div ref={drop} className={cx("drop-wrapper")}>
      {React.cloneElement(children, { isOver })}
    </div>
  );
};

export default DropWrapper;
