import classNames from "classnames/bind";
import styles from "./Toast.module.scss";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "@/features/hooks";
import { resetToast, setToast } from "@/features/toastSlice";
import Loading from "../Loading";
import { AddIcon } from "../Icons";
import { axiosClient } from "@/configs/AxiosInterceptor";
import { TASK_PREFIX, TODO_PREFIX } from "@/domain/apis";
import {
  StatusTypes,
  setTodos,
  setType,
  updateTask,
} from "@/features/toggleSlice";
import { StatusCartItem } from "@/data/types";

const cx = classNames.bind(styles);

const TodoItem = (item: any, key: number) => {
  const dispatch = useAppDispatch();
  const { item: data } = item;
  const [list, setList] = useState<any>([]);
  const [percents, setPercents] = useState<number>(0);
  const [addItem, setAddItem] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [newItem, setItem] = useState<any>();
  const inputRef = useRef<any>();

  const updateItem = async (
    isChecked: number,
    title: string,
    id: string
  ): Promise<boolean> => {
    let flat = false;
    try {
      await axiosClient.put(TODO_PREFIX + "/" + id, {
        isChecked,
        title,
      });
      return !flat;
    } catch (error) {
      console.log(error);
    }
    return flat;
  };

  const onchange = async (item: any) => {
    let isChecked = 0;
    let count = 0;
    const updatedItems = list.map((i: any, index: number) => {
      if (i._id === item._id) {
        isChecked = item.isChecked === 1 ? 0 : 1;
        if (isChecked === 1) {
          count++;
        }
        return { ...i, isChecked: isChecked };
      }
      if (i.isChecked === 1) {
        count++;
      }
      return { ...i };
    });
    if (data) {
      const result = await updateItem(isChecked, item.title, item.id);
      if (result) {
        setList([...updatedItems]);
      }
    } else {
      setList([...updatedItems]);
    }

    if (count === list.length && data) {
      console.log(123);

      const newTask = await axiosClient.put(TASK_PREFIX + "/" + data.id, {
        column: StatusCartItem.DONE,
        description: data.description,
        title: data.title,
      });
      dispatch(setType(StatusTypes.UPDATE));
      dispatch(
        updateTask({
          column: StatusCartItem.DONE,
          description: data.description,
          title: data.title,
          id: data.id,
        })
      );
      dispatch(
        setToast({
          title: "Success",
          desc: newTask.data.title,
          isOpen: false,
          status_code: newTask.data.status,
        })
      );
    }
  };

  useEffect(() => {
    if (percents == 100) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [percents]);

  useEffect(() => {
    if (list.length > 0) {
      const checkedItems = list.filter((item: any) => item.isChecked === 1);
      const result = checkedItems.length / list.length;
      setPercents(result * 100);
    }
  }, [percents, list]);

  useEffect(() => {
    if (addItem) {
      inputRef.current.focus();
    }
  }, [addItem]);

  const fetchData = useCallback(async () => {
    if (data) {
      const result = await axiosClient.get(TODO_PREFIX + "?id_task=" + data.id);
      setList(result.data.todos ?? []);

      const totalChecked = result.data.todos.reduce(function (
        accumulator: any,
        currentValue: any
      ) {
        return accumulator + currentValue.isChecked;
      },
      0);
      if (result.data.todos.length > 0) {
        const fNumber = totalChecked / result.data.todos.length;
        setPercents(fNumber * 100);
      }
    }
  }, [list]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (list.length > 0 && !data) {
      dispatch(setTodos(list));
    }
  }, [list]);

  const onHandleAddMore = async () => {
    setAddItem(true);
  };

  const onHandleAdd = async () => {
    try {
      let result: any;
      if (data) {
        result = await axiosClient.post(TODO_PREFIX, {
          task_id: data._id,
          title: newItem.title,
        });
        setList((prev: any) => [...prev, result.data.data]);
      } else {
        newItem._id = Math.random().toString(36);
        setList((prev: any) => [...prev, newItem]);
      }
      onHandleCancel();
    } catch (error) {
      console.log(error);
    }
  };

  const onHandleCancel = async () => {
    setAddItem(false);
  };

  const onChangeText = (event: any) => {
    setItem({
      isChecked: 0,
      title: event.target.value,
    });
  };

  const onDelete = async (id: any) => {
    const result = list.filter((item: any) => item._id !== id);
    if (data) {
      await axiosClient.delete(TODO_PREFIX + "/" + id);
    }
    setList([...result]);
  };

  return (
    <React.Fragment>
      <div className={cx("header-wrapper")}>
        <h2>Todo List</h2>
        {list.length > 0 && (
          <h2>{percents % 1 == 0 ? percents : percents.toFixed(2)}%</h2>
        )}
      </div>
      {list ? (
        list.map((item: any) => {
          return (
            <div key={item._id} className={cx("checkbox-container")}>
              <div className={cx("checkbox-wrapper")}>
                <input
                  type="checkbox"
                  name={item.id}
                  value={item.title}
                  checked={item.isChecked}
                  onChange={() => onchange(item)}
                  disabled={disabled}
                />
                <p
                  // onClick={() => onchange(item)}
                  style={
                    item.isChecked ? { textDecoration: "line-through" } : {}
                  }
                >
                  {item.title}
                </p>
              </div>
              <button className={cx("btn")} onClick={() => onDelete(item._id)}>
                Delete
              </button>
            </div>
          );
        })
      ) : (
        <Loading />
      )}
      {addItem && (
        <div>
          <input
            type="text"
            ref={inputRef}
            className={cx("input")}
            onChange={onChangeText}
          />
          <div className={cx("btn-wrapper")}>
            <button className={cx("btn")} onClick={onHandleAdd}>
              Add
            </button>
            <button className={cx("btn")} onClick={onHandleCancel}>
              Cancel
            </button>
          </div>
        </div>
      )}
      {!addItem && (
        <button onClick={onHandleAddMore} className={cx("btn")}>
          {AddIcon}
          <p className={cx("title")}>Add an item</p>
        </button>
      )}
    </React.Fragment>
  );
};

export default TodoItem;
