import React, { useState } from "react";
import Modal from "react-modal";
import { cartItem } from "./DropWrapper";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { task, taskSchema } from "@/pages/Auth/Schemas";
import { useAppDispatch, useAppSelector } from "@/features/hooks";
import { axiosClient } from "@/configs/AxiosInterceptor";
import { TASK_PREFIX } from "@/domain/apis";
import { StatusCartItem } from "@/data/types";
import Loading from "../Loading";
import {
  StatusTypes,
  createTask,
  setType,
  updateTask,
} from "@/features/toggleSlice";
import { setToast } from "@/features/toastSlice";
import TodoItem from "../TodoItem";
import { checkbox } from "@/data";

Modal.setAppElement("#root");

interface props {
  show: boolean;
  onClose: () => void;
  item?: cartItem;
}
const cx = classNames.bind(styles);

const Window = ({ show, onClose, item }: props) => {
  const dispatch = useAppDispatch();
  const { todo } = useAppSelector((state) => state.toggle);
  const [loading, setIsLoading] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
  } = useForm<task>({
    defaultValues: {
      title: item ? item.title : "",
      description: item ? item.description : "",
    },
    resolver: yupResolver(taskSchema),
  });

  const onSave = async (data: task) => {
    try {
      setIsLoading(true);
      if (item) {
        const { description, title } = data;
        const formData = new FormData();
        formData.append("column", item.column);
        formData.append("description", description);
        formData.append("title", title);
        const newTask = await axiosClient.put(
          TASK_PREFIX + "/" + item.id,
          formData
        );
        dispatch(setType(StatusTypes.UPDATE));
        dispatch(
          updateTask({
            column: item.column,
            description: description,
            title: title,
            id: item.id,
          })
        );
        dispatch(
          setToast({
            title: "Success",
            desc: newTask.data.title,
            isOpen: false,
            status_code: newTask.data.status,
          })
        );
        onClose();
      } else {
        const { description, title } = data;
        const formData = new FormData();
        formData.append("column", StatusCartItem.TODO);
        formData.append("description", description);
        formData.append("title", title);
        const todoList = todo.map((item: any) => {
          return { isChecked: item.isChecked, title: item.title };
        });
        formData.append("todos", JSON.stringify(todoList));
        const newTask = await axiosClient.post(TASK_PREFIX, formData);
        dispatch(setType(StatusTypes.CREATE));
        dispatch(createTask(newTask.data.data));
        dispatch(
          setToast({
            title: "Success",
            desc: newTask.data.title,
            isOpen: false,
            status_code: newTask.data.status,
          })
        );
        reset();
        onClose();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={show}
      onRequestClose={onClose}
      className={cx("modal", "overlay")}
    >
      <div className={cx("container")}>
        {loading && (
          <div className={cx("loading-container")}>
            <Loading />
          </div>
        )}
        <div className={cx("close-btn-ctn")}>
          <div className={cx("input-wrapper")}>
            <input
              {...register("title")}
              type="text"
              style={{
                backgroundColor: "white",
                borderRadius: 10,
                padding: 16,
                width: "100%",
              }}
              defaultValue={item ? item.title : ""}
              placeholder={"Title"}
            />
            <p className={cx("error-msg")}>{errors.title?.message}</p>
          </div>
          <button className={cx("close-btn")} onClick={onClose}>
            X
          </button>
        </div>
        <div>
          <h2>Description</h2>

          <textarea
            {...register("description")}
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              width: "100%",
              height: 150,
              padding: 16,
              resize: "none",
            }}
            defaultValue={item ? item.description : ""}
          />
          <p className={cx("error-msg")}>{errors.description?.message}</p>
          <TodoItem item={item} />
          <div
            style={{ width: "100%", display: "flex", justifyContent: "end" }}
          >
            <button
              style={{ color: "white", marginTop: 20 }}
              onClick={handleSubmit(onSave)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Window;
