import { createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

type SessionState = {
  login: boolean;
  refresh_token: string;
  access_token: string;
};

const initialState = (): SessionState => {
  return { login: false, refresh_token: "", access_token: "" };
};

export const sessionSlice = createSlice({
  name: "session",
  initialState: initialState(),
  reducers: {
    resetSession: () => {
      return initialState();
    },
    setSessionLogin: (state, action) => {
      state.login = true;
      state.refresh_token = action.payload.refresh_token;
      state.access_token = action.payload.access_token;
      localStorage.setItem("refresh_token", action.payload.refresh_token);
      localStorage.setItem("access_token", action.payload.access_token);
    },
    setSessionLogout: (state, action) => {
      localStorage.clear();
    },
    getSessionToken: (state, action) => {
      state.refresh_token = localStorage.getItem("refresh_token") ?? "";
      state.access_token = localStorage.getItem("access_token") ?? "";
    },
  },
});

export const { resetSession, setSessionLogin, setSessionLogout } =
  sessionSlice.actions;

export default sessionSlice.reducer;
