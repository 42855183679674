import { createSlice } from "@reduxjs/toolkit";
import { cartItem } from "@/components/Card/DropWrapper";

export enum StatusTypes {
  CREATE = "CREATE",
  DELETE_ONE = "DELETE_ONE",
  DELETE_ALL = "DELETE_ALL",
  UPDATE = "UPDATE",
  EMPTY = "",
}

export enum WindowType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

type ToggleState = {
  task: cartItem | undefined;
  tasks: cartItem[];
  isLoading: boolean;
  type: StatusTypes;
  type_window: WindowType;
  todo: any;
};

const initialState = (): ToggleState => {
  return {
    tasks: [],
    task: undefined,
    isLoading: false,
    type: StatusTypes.EMPTY,
    type_window: WindowType.CREATE,
    todo: [],
  };
};

export const TaskSlice = createSlice({
  name: "tasks",
  initialState: initialState(),
  reducers: {
    resetTasks: () => {
      return initialState();
    },
    createTask: (state, action) => {
      state.task = action.payload;
    },
    updateTask: (state, action) => {
      state.task = action.payload;
    },
    deleteTask: (state, action) => {
      state.task = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = !action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setTypeWindow: (state, action) => {
      state.type_window = action.payload;
    },
    setTodos: (state, action) => {
      state.todo = action.payload;
    },
  },
});

export const {
  resetTasks,
  createTask,
  deleteTask,
  setLoading,
  setType,
  updateTask,
  setTypeWindow,
  setTodos,
} = TaskSlice.actions;

export default TaskSlice.reducer;
