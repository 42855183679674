import React, { Fragment, useEffect, useState } from "react";
import PanelControl from "./pages/Auth/Login";
import { Route, Routes, useNavigate } from "react-router-dom";
import { HOME_ADDR, LOGIN, SIGNIN } from "./domain/routing";
import { useAppDispatch, useAppSelector } from "./features/hooks";
import { setSessionLogin, setSessionLogout } from "./features/sessionSlice";
import { DefaultLayout } from "@/components/Layout";
import {
  BASE_URL,
  GET_PROFILE_USER,
  USER_PREFIX,
} from "./domain/apis";
import { reSetProfile, setProfile } from "./features/profileSlice";
import { axiosClient } from "./configs/AxiosInterceptor";
import { privateRoutes, publicRoutes } from "./routes";
import { setToast } from "./features/toastSlice";

function App() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { access_token: token, refresh_token: refreshToken } = useAppSelector(
    (state) => state.session
  );
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const { email } = useAppSelector((state) => state.profile);
  const access_token = localStorage.getItem("access_token");
  const refresh_token = localStorage.getItem("refresh_token");
  const profile = localStorage.getItem("profile");

  async function fetchData() {
    try {
      const user = await axiosClient.get(
        BASE_URL + USER_PREFIX + GET_PROFILE_USER
      );

      dispatch(
        setProfile({
          email: user.data.email,
          name: user.data.name,
          img: user.data.avatar_url,
        })
      );
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (token) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, token, email]);

  // current window
  useEffect(() => {
    if (!access_token) {
      setInterval(() => {
        const accessToken = localStorage.getItem("access_token");
        const message = localStorage.getItem("message_error");
        if (message) {
          dispatch(
            setToast({
              title: "Error",
              desc: message,
              isOpen: false,
              status_code: 404,
            })
          );
        }

        if (accessToken) {
          const refreshToken = localStorage.getItem("refresh_token");
          dispatch(reSetProfile({ email: "", name: "", img: "" }));
          dispatch(
            setSessionLogin({
              access_token: accessToken,
              refresh_token: refreshToken,
            })
          );
          const login = localStorage.getItem("login");

          if (login) {
            localStorage.setItem("login", "");
          }
        } else {
          const message = localStorage.getItem("message_error");

          if (message) {
            setTimeout(() => {
              dispatch(
                setSessionLogout({
                  access_token: token,
                  refresh_token: refreshToken,
                })
              );
            }, 2000);
          }
        }
      }, 1000);
    }
  }, [access_token]);

  useEffect(() => {
    if (access_token) {
      dispatch(
        setSessionLogin({
          access_token: access_token,
          refresh_token: refresh_token,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (!access_token && !token) {
      navigate(LOGIN);
    } else {
      navigate(HOME_ADDR);
    }
  }, [access_token, token]);

  if (!access_token) {
    return (
      <Routes>
        {privateRoutes.map((route, index) => {
          const { Component } = route;
          return (
            <Route
              key={index}
              path={route.path}
              element={Component ? <Component /> : <></>}
            />
          );
        })}
      </Routes>
    );
  }

  return (
    <Routes>
      {publicRoutes.map((route, index) => {
        const { Component } = route;
        let Layout = DefaultLayout;
        return (
          <Route
            key={index}
            path={route.path}
            element={
              Component ? (
                <Layout>
                  <Component />
                </Layout>
              ) : (
                <></>
              )
            }
          />
        );
      })}
    </Routes>
  );
}

export default App;
