import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

interface IProps {
  isOver: boolean;
  children: any;
}

const Col = ({ isOver, children }: IProps) => {
  const className = cx("col", { "highlight-region": isOver });

  return <div className={className}>{children}</div>;
};

export default Col;
