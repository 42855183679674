import { StatusCartItem } from "./types";

const data = [
  {
    id: 1,
    icon: "⭕️",
    status: StatusCartItem.TODO,
    title: "Human Interest Form",
    content: "Fill out human interest distribution form",
  },
  {
    id: 2,
    icon: "⭕️",
    status: StatusCartItem.TODO,
    title: "Purchase present",
    content: "Get an anniversary gift",
  },
  {
    id: 3,
    icon: "⭕️",
    status: StatusCartItem.TODO,
    title: "Invest in investments",
    content: "Call the bank to talk about investments",
  },
  {
    id: 4,
    icon: "⭕️",
    status: StatusCartItem.TODO,
    title: "Daily reading",
    content: "Finish reading Intro to UI/UX",
  },
];

const checkbox = [
  {
    id: 1,
    isChecked: true,
    title: "Human Interest Form",
  },
  {
    id: 2,
    isChecked: false,
    title: "Purchase present",
  },
  {
    id: 3,
    isChecked: false,
    title: "Invest in investments",
  },
  {
    id: 4,
    isChecked: true,
    title: "Daily reading",
  },
];

const statuses = [
  {
    status: StatusCartItem.TODO,
    icon: "⭕️",
    color: "#EB5A46",
  },
  {
    status: StatusCartItem.DONE,
    icon: "✅",
    color: "#3981DE",
  },
];

export { data, statuses, checkbox };
