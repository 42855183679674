import classNames from "classnames/bind";
import styles from "./Toast.module.scss";
import { ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "@/features/hooks";
import { resetToast } from "@/features/toastSlice";

const cx = classNames.bind(styles);

const Toast = ({ children }: { children: ReactNode }) => {
  const [close, setClose] = useState<boolean>(false);
  const [colorBackground, setColorBackground] = useState<string>("");
  const dispatch = useDispatch();
  const { title, desc, isOpen, status_code } = useAppSelector(
    (state) => state.toast
  );

  function onClick() {}

  useEffect(() => {
    if (status_code >= 200 && status_code < 300) {
      setColorBackground("#30A048");
    } else {
      setColorBackground("red");
    }

    const timeOut = setTimeout(() => {
      dispatch(resetToast());
    }, 2000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={cx(
          "notification-container",
          isOpen ? "top-right" : "disappear"
        )}
      >
        <div
          className={cx("notification-wrapper")}
          style={colorBackground ? { backgroundColor: colorBackground } : {}}
        >
          <button onClick={onClick} className={cx("btn-close")}>
            X
          </button>

          <div>
            <p className={cx("notification-title")}>{title}</p>
            <p className={cx("notification-message")}>{desc}</p>
          </div>
        </div>
      </div>
      {children}
    </>
  );
};

export default Toast;
