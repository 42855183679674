import { createSlice } from "@reduxjs/toolkit";

type ProfileState = {
  name: string;
  img: string;
  email: string;
};

const initialState = (): ProfileState => {
  return { name: "", img: "", email: "" };
};

export const profileSlice = createSlice({
  name: "contact",
  initialState: initialState(),
  reducers: {
    resetProfile: () => {
      return initialState();
    },
    setProfile: (state, action) => {
      state.name = action.payload.name ?? "";
      state.img = action.payload.img ?? "";
      state.email = action.payload.email ?? "";
      localStorage.setItem(
        "profile",
        `{"name":"${action.payload.name}", "img":"${action.payload.img}", "email":"${action.payload.email}"}`
      );
    },
    reSetProfile: (state, action) => {
      const profile = localStorage.getItem("profile") || "";
      if (!profile) {
        return initialState();
      }
      const profileState = JSON.parse(profile);
      state.name = profileState.name ?? "";
      state.img = profileState.img ?? "";
      state.email = profileState.email ?? "";
    },
  },
});

export const { resetProfile, setProfile, reSetProfile } = profileSlice.actions;

export default profileSlice.reducer;
