import { createSlice } from "@reduxjs/toolkit";

type ToastState = {
  isOpen: boolean;
  title: string;
  desc: string;
  status_code: number;
};

const initialState = (): ToastState => {
  return { isOpen: false, title: "", desc: "", status_code: 0 };
};

export const ToastSlice = createSlice({
  name: "toast",
  initialState: initialState(),
  reducers: {
    resetToast: () => {
      return initialState();
    },
    setToast: (state, action) => {
      state.isOpen = !action.payload.isOpen;
      state.title = action.payload.title;
      state.desc = action.payload.desc;
      state.status_code = action.payload.status_code;
    },
  },
});

export const { resetToast, setToast } = ToastSlice.actions;

export default ToastSlice.reducer;
