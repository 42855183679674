export const USER_PREFIX = "/users";
export const TASK_PREFIX = "/tasks";
export const TODO_PREFIX = "/todos";
export const GET_PROFILE_USER = "/current-user";
export const FIND_USER_BY_EMAIL = "/find-user-by-email";
export const AUTH_PREFIX = "/auth/gitlab";
export const BASE_URL = "https://nguyenlam.gcalls.vn/api";
export const LOGOUT = "/logout";
export const REDIRECT_OAUTH_URL =
  "/oauth/login?redirect_uri=https://nguyenlam.gcalls.vn";
export const LOGIN = "/oauth/login";
export const SIGNUP = "/oauth/signup";
