import React, { Fragment, useState, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Window from "./Window";
import ITEM_TYPE, { StatusCartItem } from "@/data/types";
import { cartItem } from "./DropWrapper";
import classNames from "classnames/bind";
import styles from "./index.module.scss";
import { ClearIcon } from "../Icons";
import { axiosClient } from "@/configs/AxiosInterceptor";
import { TASK_PREFIX } from "@/domain/apis";
import { useAppDispatch } from "@/features/hooks";
import {
  StatusTypes,
  WindowType,
  deleteTask,
  setType,
  setTypeWindow,
} from "@/features/toggleSlice";
import { setToast } from "@/features/toastSlice";
interface IProps {
  item: cartItem;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  status: ISTATUS;
  loading: boolean;
}

export interface ISTATUS {
  color: string;
  icon: string;
  status: string;
}
const cx = classNames.bind(styles);

const Item = ({ item, index, moveItem, status, loading }: IProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLInputElement>(null);

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    hover(item: cartItem, monitor) {
      if (loading) {
        return;
      }
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index ?? 0;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoveredRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoveredRect.bottom - hoveredRect.top) / 2;
      const mousePosition = monitor.getClientOffset();
      const hoverClientY =
        (mousePosition ? mousePosition.y : 0) - hoveredRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: () => {
      return { type: ITEM_TYPE, ...item, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [show, setShow] = useState(false);

  const onOpen = () => {
    dispatch(setTypeWindow(WindowType.UPDATE));
    setShow(true);
  };

  const onClose = () => setShow(false);

  const onRemove = async (item: cartItem) => {
    try {
      const result = await axiosClient.delete(TASK_PREFIX + "/" + item.id);
      dispatch(setType(StatusTypes.DELETE_ONE));
      dispatch(deleteTask(item));
      dispatch(
        setToast({
          title: "Success",
          desc: result.data.title,
          isOpen: false,
          status_code: result.data.status,
        })
      );
    } catch (error) {}
  };

  drag(drop(ref));

  return (
    <Fragment>
      {item && (
        <div
          ref={ref}
          style={{ opacity: isDragging ? 0 : 1 }}
          className={cx("item")}
        >
          <button className={cx("item-btn")} onClick={() => onRemove(item)}>
            {ClearIcon}
          </button>
          <div className={cx("item-content-wrapper")} onClick={onOpen}>
            <div className={cx("item-title-wrapper")}>
              <div
                className={cx("color-bar")}
                style={{ backgroundColor: status.color }}
              />
            </div>
            <p className={cx("item-title")}>{item.title}</p>
            <p className={cx("item-status")}>
              {item.column === StatusCartItem.TODO ? "⭕️" : "✅"}
            </p>
          </div>
        </div>
      )}
      <Window item={item} onClose={onClose} show={show} />
    </Fragment>
  );
};

export default Item;
