import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames/bind";
import styles from "./Register.module.scss";
import { BlindEye, CircleUser, Email, Eye, Lock } from "@/components/Icons";
import { LOGIN } from "@/domain/routing";
import { RegisterForm, registerSchema } from "../Schemas";
import { yupResolver } from "@hookform/resolvers/yup";
import authStyles from "../AuthStyles.module.scss";
import { axiosClient, errorTypes } from "@/configs/AxiosInterceptor";
import { SIGNUP } from "@/domain/apis";
import { useDispatch } from "react-redux";
import { setToast } from "@/features/toastSlice";

const cx = classNames.bind(styles);
const authCx = classNames.bind(authStyles);

export default function Register(): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hidePsw, setHidePsw] = useState<boolean>(false);
  const [hidePswConfirmation, setHidePswConfirmation] =
    useState<boolean>(false);
  const [loadings, setLoadings] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterForm>({
    defaultValues: {
      email: "",
      username: "",
      password: "",
      confirmPassword: "",
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = async (data: any) => {
    setLoadings(true);
    try {
      data.provider = "jwt";
      const result = await axiosClient.post(SIGNUP, data);
      setTimeout(() => {
        navigate(LOGIN);
        setLoadings(false);
      }, 1000);
      dispatch(
        setToast({
          title: "Success",
          desc: result.data.message,
          isOpen: false,
          status_code: result.status,
        })
      );
    } catch (error: any) {
      const err: errorTypes = error;
      setTimeout(() => {
        setLoadings(false);
      }, 1000);

      dispatch(
        setToast({
          title: "Error",
          desc: err.response.data.message,
          isOpen: false,
          status_code: err.response.status,
        })
      );
    }
  };

  const onChangeStatePswConfirmation = () => {
    setHidePswConfirmation(!hidePswConfirmation);
  };

  const onChangeStatePsw = () => {
    setHidePsw(!hidePsw);
  };

  return (
    <div className={cx("wrapper")}>
      <div className={cx("logo-wrapper")}>
        <img src={"https://app.gcalls.co/assets/img/logo.png"} />
      </div>

      {/* form */}
      <div className={cx("form-wrapper")}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{Email}</div>
              <input
                {...register("email")}
                placeholder="Email"
                className={cx("input-item")}
              />
              <div className={cx("right-icon-wrapper")}></div>
            </div>
            <p className={authCx("error-msg")}>{errors.email?.message}</p>
          </div>
          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{CircleUser}</div>
              <input
                {...register("username")}
                placeholder="Username"
                className={cx("input-item")}
              />
              <div className={cx("right-icon-wrapper")}></div>
            </div>
            <p className={authCx("error-msg")}>{errors.username?.message}</p>
          </div>
          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{Lock}</div>
              <input
                {...register("password")}
                placeholder="Password"
                className={cx("input-item")}
                type={!hidePsw ? "password" : "text"}
              />
              <div className={cx("right-icon-wrapper")}>
                <div onClick={onChangeStatePsw}>{hidePsw ? Eye : BlindEye}</div>
              </div>
            </div>
            <p className={authCx("error-msg")}>{errors.password?.message}</p>
          </div>

          <div className={authCx("input-container")}>
            <div className={cx("input-wrapper")}>
              <div className={cx("left-icon-wrapper")}>{Lock}</div>
              <input
                {...register("confirmPassword")}
                placeholder="Password Confirmation"
                className={cx("input-item")}
                type={!hidePswConfirmation ? "password" : "text"}
              />
              <div className={cx("right-icon-wrapper")}>
                <div onClick={onChangeStatePswConfirmation}>
                  {hidePswConfirmation ? Eye : BlindEye}
                </div>
              </div>
            </div>
            <p className={authCx("error-msg")}>
              {errors.confirmPassword?.message}
            </p>
          </div>

          <div className={cx("register-navigation-link-wrapper")}>
            <a
              href="#"
              className={cx("register-navigation-link")}
              onClick={() => {
                navigate(LOGIN);
              }}
            >
              Have already an account?
            </a>
          </div>
          <input
            type="submit"
            disabled={loadings}
            value={"Register"}
            style={loadings ? { opacity: 0.8 } : {}}
          />
        </form>
      </div>
    </div>
  );
}
